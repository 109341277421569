import * as EmailValidator from 'email-validator';
import { Field, Form, Formik } from 'formik';
import React, { FC } from 'react';

import { Trans, t } from '@lingui/macro';
import { Button, Chip, Grid, TextField as MuiTextField, Typography } from '@mui/material';

import { FormikFormErrors } from 'src/components/FormikFormErrors';
import Drawer from 'src/components/common/drawer';
import Tooltip from 'src/components/common/tooltip';
import { TextField } from 'src/components/formik-material-ui/TextField';
import SwitchField from 'src/forms/switchField';
import useIsAutoInviteEnabled from 'src/hooks/useIsAutoIntiveEnabled';
import { ContactInformationValidationSchema } from 'src/project/contactInformationValidation';
import { useAppDispatch } from 'src/redux/hooks';
import { updateVendorsAction } from 'src/sagas/actions';

import {
    useContactInformationUserIndividual,
    useFullProject,
    useProjectVendorsSelector,
    useValidateAndAutocompleteProjectVendorContact,
} from '../state/hook';
import { clearContactInformationUser } from '../state/projectVendorsSlice';
import { Contact, ProjectVendor } from '../types';

interface ContactInformationDrawerProps {
    open: boolean;
    onClose: () => void;
    contact: Contact;
    vendor: ProjectVendor;
}

const ContactInformationDrawer: FC<ContactInformationDrawerProps> = ({
    open,
    onClose: handleClose,
    contact,
    vendor,
}: ContactInformationDrawerProps) => {
    const isAutoInviteEnabled = useIsAutoInviteEnabled();
    const project = useFullProject();
    const dispatch = useAppDispatch();
    const projectVendors = useProjectVendorsSelector();
    const { companies } = projectVendors;
    const validateAndAutocompleteProjectVendorContact =
        useValidateAndAutocompleteProjectVendorContact();
    const contactInformationUser = useContactInformationUserIndividual();
    const companyName = contactInformationUser?.company
        ? contactInformationUser?.company?.companyNameAlias
        : vendor?.companyName;
    const beforeClose = () => {
        dispatch(clearContactInformationUser());
        handleClose();
    };

    const initialValues =
        (contactInformationUser?.existingContactUser?.firstName &&
            contactInformationUser.existingContactUser) ||
        (contact.individual
            ? {
                  ...contact.individual,
                  autoinvite: contact.autoinvite,
              }
            : {
                  firstName: '',
                  surname: '',
                  email: '',
                  phone: '',
                  autoinvite: contact.autoinvite,
              });

    const onSubmit = React.useCallback(
        (values, { setSubmitting }) => {
            const projectVendorIDs = companies.map((item) => item.id);
            dispatch(
                updateVendorsAction({
                    companies: projectVendorIDs,
                    id: project.id,
                    contactInformationUser,
                    contact: {
                        ...contact,
                        autoinvite: values.autoinvite,
                    },
                    individual: values,
                    projectVendors,
                }),
            );

            setSubmitting(false);
            handleClose();
        },
        [contact, handleClose, companies],
    );
    const handleEmailblur = React.useCallback(
        (e) => {
            const email = e.target.value;
            if (EmailValidator.validate(email)) {
                validateAndAutocompleteProjectVendorContact(email);
            }
        },
        [contactInformationUser],
    );

    return (
        <Drawer
            style={{ zIndex: 1300 }}
            ModalProps={{
                onBackdropClick: beforeClose,
            }}
            anchor="right"
            open={open}
        >
            <Formik
                initialValues={initialValues}
                validationSchema={ContactInformationValidationSchema}
                onSubmit={onSubmit}
                enableReinitialize
            >
                {({ status, isSubmitting, submitForm }): JSX.Element => (
                    <>
                        <Form>
                            <Grid container flexDirection="column" gap={2} p={4}>
                                <Typography variant="h6">
                                    <Trans>Company information</Trans>
                                </Typography>
                                <MuiTextField
                                    fullWidth
                                    label={<Trans>Company name</Trans>}
                                    value={companyName}
                                    variant="outlined"
                                    disabled
                                />
                                <Typography variant="h6">
                                    <Trans>Client information</Trans>
                                </Typography>
                                <Field
                                    fullWidth
                                    component={TextField}
                                    name="email"
                                    onBlur={handleEmailblur}
                                    label={<Trans>Email address</Trans>}
                                    data-testid="individualEmail"
                                />
                                <Field
                                    fullWidth
                                    component={TextField}
                                    name="firstName"
                                    label={<Trans>First name</Trans>}
                                    data-testid="individualFirstName"
                                    disabled={
                                        contactInformationUser?.existingContactUser?.firstName ||
                                        contact.isVerified
                                    }
                                />
                                <Field
                                    fullWidth
                                    component={TextField}
                                    name="surname"
                                    label={<Trans>Last name</Trans>}
                                    data-testid="individualLastName"
                                    disabled={
                                        contactInformationUser?.existingContactUser?.surname ||
                                        contact.isVerified
                                    }
                                />
                                <Field
                                    fullWidth
                                    component={TextField}
                                    name="phone"
                                    label={<Trans>Phone number</Trans>}
                                    data-testid="individualPhone"
                                    disabled={
                                        !!contactInformationUser?.existingContactUser?.phone ||
                                        contact.isVerified
                                    }
                                />
                                {isAutoInviteEnabled &&
                                    (!!contactInformationUser || contact.isVerified) && (
                                        <Grid
                                            container
                                            justifyContent="space-between"
                                            alignItems="center"
                                        >
                                            <Tooltip
                                                placement="right"
                                                title={t`Invitation emails can be sent automatically for validated users. This user is validated, if you wish, the invitation email will be sent automatically upon submission of the event. Uncheck this box if you are unsure. apadua staff will then check this user before sending the invitation.`}
                                            >
                                                <Chip
                                                    label={<Trans>Verified contact</Trans>}
                                                    color="success"
                                                />
                                            </Tooltip>
                                            <SwitchField
                                                name="autoinvite"
                                                label={<Trans>Invite automatically</Trans>}
                                            />
                                        </Grid>
                                    )}
                                <FormikFormErrors status={status} />
                                <Grid container gap={2}>
                                    <Button
                                        color="secondary"
                                        variant="contained"
                                        onClick={handleClose}
                                        data-testid="saveContactInformation"
                                    >
                                        <Trans>Cancel</Trans>
                                    </Button>
                                    <Button
                                        color="primary"
                                        onClick={submitForm}
                                        disabled={isSubmitting}
                                        variant="contained"
                                        data-testid="saveContactInformation"
                                    >
                                        <Trans>Save contact information</Trans>
                                    </Button>
                                </Grid>
                            </Grid>
                        </Form>
                    </>
                )}
            </Formik>
        </Drawer>
    );
};

export default ContactInformationDrawer;
